import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Hidden } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

function Header(props) {
  const { handleDrawerToggle } = props;
  const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
      display: "block",
      height: "50px",
    },
    emailIcon: {
      marginRight: "5px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  return (
    <Hidden mdUp implementation="css">
      <AppBar position="relative" className={classes.appBar} elevation={1} color="white">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}

export default Header;
