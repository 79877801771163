import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { formatName } from "../../utils/string";
import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";

function HouseMembership() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
    personList: {
      listStyleType: "none",
    },
    personListItem: {
      marginBottom: "15px",
    }
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/house`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      {data.length > 0 && (
        <ul className={classes.personList}>
          {data.map((s, i) => (
            <li className={classes.personListItem} key={i}>
              <Link to={`/legislator/${s.ID}`}>{formatName(s.FirstName, s.MiddleName, s.LastName)}</Link>
            </li>
          ))}
        </ul>
      )}
    </Paper>
  );
}

export default HouseMembership;
