import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, CircularProgress, TableBody, TableContainer, TableRow, TableCell, Table, Typography } from "@material-ui/core";
import { formatName, isNull } from "../../utils/string";
import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";
import { Alert } from "@material-ui/lab";
import { getPixUrl } from "../../utils/link";

function Judicial() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
    tableHead: {
      fontWeight: "bold",
      width: "20%"
    },
    image: {
      width: "90%"
    }
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const { id } = useParams();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/executive/${id}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Typography variant="h3">
      {formatName(data.FirstName, data.MiddleName, data.LastName)}
      {!isNull(data.PartyAffiliation) && (
        (data.PartyAffiliation)
      )}
    </Typography>

    <Grid container>
      {!isNull(data.pix) && (
        <Grid item md={4}>
          <img className={classes.image} alt={formatName(data.FirstName, data.MiddleName, data.LastName)} src={getPixUrl(data.pix, "2015ExecutivePhotos")} />
        </Grid>
      )}
      <Grid item md={!isNull(data.pix) ? 8 : 12}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {!isNull(data.TheSection) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Agency</TableCell>
                  <TableCell>{data.TheSection}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Division) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Division</TableCell>
                  <TableCell>{data.Division}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Email) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>E-Mail</TableCell>
                  <TableCell>{data.Email}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Phone) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Phone</TableCell>
                  <TableCell>{data.Phone}</TableCell>
                </TableRow>
              )}
              {!isNull(data.StreetAddress) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Address</TableCell>
                  <TableCell>{data.StreetAddress}<br />{data.City}, {data.State} {data.Zip}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Staff) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Staff</TableCell>
                  <TableCell>{data.Staff}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Website) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Webpage</TableCell>
                  <TableCell>{data.Website}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Twitter) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Twitter Handle</TableCell>
                  <TableCell>{data.Twitter}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Staff) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Staff</TableCell>
                  <TableCell>{data.Staff}</TableCell>
                </TableRow>
              )}
              {!isNull(data.DateofBirth) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>D.O.B</TableCell>
                  <TableCell>{data.DateofBirth}</TableCell>
                </TableRow>
              )}
              {!isNull(data.PlaceOfBirth) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Place of Birth</TableCell>
                  <TableCell>{data.PlaceOfBirth}</TableCell>
                </TableRow>
              )}
              {!isNull(data.PlaceFrom) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Town of Residence</TableCell>
                  <TableCell>{data.PlaceFrom}</TableCell>
                </TableRow>
              )}
              {!isNull(data.MaritalStatus) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Marital Status</TableCell>
                  <TableCell>{data.MaritalStatus}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Children) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Children</TableCell>
                  <TableCell>{data.Children}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Education) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Education</TableCell>
                  <TableCell>{data.Education}</TableCell>
                </TableRow>
              )}
              {!isNull(data.PreviousEmployment) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Previous Employment</TableCell>
                  <TableCell>{data.PreviousEmployment}</TableCell>
                </TableRow>
              )}
              {!isNull(data.YearsInStateService) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Years in State Service</TableCell>
                  <TableCell>{data.YearsInStateService}</TableCell>
                </TableRow>
              )}
              {!isNull(data.StartDate) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Start Date</TableCell>
                  <TableCell>{data.StartDate}</TableCell>
                </TableRow>
              )}
              {!isNull(data.JobDescription) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Job Description</TableCell>
                  <TableCell>{data.JobDescription}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Priorities) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Priorities</TableCell>
                  <TableCell>{data.Priorities}</TableCell>
                </TableRow>
              )}
              {!isNull(data.Organizations) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>Organizations</TableCell>
                  <TableCell>{data.Organizations}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </>);
}

export default Judicial;
