import React, { useState, useEffect } from "react";
import { Switch, NavLink, Route, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Box, Hidden, Container, Divider, ListSubheader, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FiberNew as FiberIcon, Search as SearchIcon, Home as HomeIcon, Book as BookIcon } from "@material-ui/icons";

import { useAuth } from "../contexts/auth";
import { getError } from "../utils/constants";

import News from "../routes/news";
import Search from "../routes/search";
import LatestUpdates from "../routes/latest-updates";
import Vacancies from "../routes/vacancies";

import Districts from "../routes/districts";

import SenateMembership from "../routes/legislature/senate-membership";
import Legislator from "../routes/legislature/legislator";
import HouseMembership from "../routes/legislature/house-membership";
import CongressionalDelegation from "../routes/legislature/congressional-delegation";
import CD from "../routes/legislature/cd";
import LegislatureLeadership from "../routes/legislature/legislative-leadership";
import LegislatureCommittees from "../routes/legislature/legislative-committees";
import Chairs from "../routes/legislature/chairs";
import Committee from "../routes/legislature/committee";

import ExecutiveOffices from "../routes/executive/executive-offices";
import IndependentQuasiPublicAgencies from "../routes/executive/independent-quasi-public-agencies";
import AgencyDirectory from "../routes/executive/agency-directory";
import GovernorsCouncil from "../routes/executive/governors-council";
import Executive from "../routes/executive/executive";
import Division from "../routes/executive/division";
import Agency from "../routes/executive/agency";
import ExecutiveOffice from "../routes/executive/executive-office";

import SupremeJudicialCourt from "../routes/judicial/supreme-judicial-court";
import AppealsCourt from "../routes/judicial/appeals-court";
import TrialCourt from "../routes/judicial/trial-court";
import Judicial from "../routes/judicial/judicial";

import Counties from "../routes/sheriffs/counties";
import CountySheriffs from "../routes/sheriffs/county-sheriffs";
import CountySheriff from "../routes/sheriffs/sheriff";

import PersonnelFile from "../routes/personnel-files/personnel-file";

function Navigation(props) {
  const { handleDrawerToggle, mobileOpen } = props;
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: "300px",
    },
    menu: {
      backgroundColor: "rgb(245, 242, 228)",
      width: "300px",
    },
    mainContainer: {
      flexGrow: 1,
    },
    logo: {
      display: "block",
      width: "30%",
      margin: `${theme.spacing(1)}px auto`,
    },
    headerLink: {
      textDecoration: "none",
      textAlign: "center",
      marginBottom: `${theme.spacing(1)}px`,
      color: "#000000",
    },
    headerTitle: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  }));

  const { token } = useAuth();
  const classes = useStyles();

  const [isContainerLoading, setIsContainerLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/home`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();

      setData({ news: resJson.entries, freetext: resJson.freetext, personnel_files: resJson.personnel_files });
    } catch (e) {
      console.error(e);
      setError({ type: "error", open: true, message: getError(e) });
    } finally {
      setIsContainerLoading(false);
    }
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  const MAIN_LINKS = [{
    link: "/",
    icon: <HomeIcon />,
    text: "Home"
  }, {
    link: "/search",
    icon: <SearchIcon />,
    text: "Search"
  }, {
    link: "/latest",
    icon: <FiberIcon />,
    text: "Latest Updates"
  }];

  const drawer = isMobile => (<>
    {MAIN_LINKS.map((l, i) => (
      <ListItem key={i} button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={l.link}>
        <ListItemIcon>
          {l.icon}
        </ListItemIcon>
        <ListItemText primary={l.text} />
      </ListItem>
    ))}
    <ListItem button dense onClick={() => window.location.href = "http://massachusettsalmanac.com"}>
      <ListItemIcon>
        <BookIcon />
      </ListItemIcon>
      <ListItemText primary="Xtras" />
    </ListItem>

    <Divider />

    <ListSubheader>Legislature</ListSubheader>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/senate-membership"}>
      <ListItemText primary="Senate Membership" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/house-membership"}>
      <ListItemText primary="House Membership" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/legislative-leadership"}>
      <ListItemText primary="Legislative Leadership" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/legislative-committees"}>
      <ListItemText primary="Legislative Committees" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/districts"}>
      <ListItemText primary="District Profiles" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/congressional-delegation"}>
      <ListItemText primary="Congressional Delegation" />
    </ListItem>

    <Divider />

    <ListSubheader>Executive</ListSubheader>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/executive-offices"}>
      <ListItemText primary="Executive Offices" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/independent-quasi-public-agencies"}>
      <ListItemText primary="Independent/Quasi-Public Agencies" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/agency-directory"}>
      <ListItemText primary="Agency Directory" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/governors-council"}>
      <ListItemText primary="Governor's Council" />
    </ListItem>

    <Divider />

    <ListSubheader>Judicial</ListSubheader>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/supreme-judicial-court"}>
      <ListItemText primary="Supreme Judicial Court" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/appeals-court"}>
      <ListItemText primary="Appeals Court" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/trial-court"}>
      <ListItemText primary="Trial Court" />
    </ListItem>

    <Divider />

    <ListSubheader>Sheriffs</ListSubheader>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/county-sheriffs"}>
      <ListItemText primary="County Sheriffs" />
    </ListItem>
  </>);

  return (<>
    <Hidden mdUp implementation="css">
      <Drawer
        className={classes.drawer}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.menu,
        }}
      >
        <Link className={classes.headerLink} to="/">
          <img className={classes.logo} src="/logo.png" alt="AlmanAPP" />
          <Typography variant="h1" className={classes.headerTitle}>
            The Massachusetts Almanac
          </Typography>
        </Link>
        {drawer(true)}
      </Drawer>
    </Hidden>

    <Hidden smDown implementation="css">
      <Box displayPrint="none">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.menu,
          }}
        >
          <Link className={classes.headerLink} to="/">
            <img className={classes.logo} src="/logo.png" alt="AlmanAPP" />
            <Typography variant="h1" className={classes.headerTitle}>
              The Massachusetts Almanac
            </Typography>
          </Link>
          {drawer(false)}
        </Drawer>
      </Box>
    </Hidden>

    <main className={classes.mainContainer}>
      {!isContainerLoading && <Container maxWidth={false} disableGutters dangerouslySetInnerHTML={{ __html: data.freetext.primary_content }} />}

      <Switch>
        <Route exact={true} path="/">
          <News />
        </Route>
        <Route path="/search">
          <Search />
        </Route>
        <Route path="/latest">
          <LatestUpdates />
        </Route>
        <Route path="/vacancies">
          <Vacancies />
        </Route>

        <Route path="/districts">
          <Districts />
        </Route>

        <Route path="/senate-membership">
          <SenateMembership />
        </Route>
        <Route path="/legislator/:id">
          <Legislator />
        </Route>
        <Route path="/house-membership">
          <HouseMembership />
        </Route>
        <Route path="/congressional-delegation">
          <CongressionalDelegation />
        </Route>
        <Route path="/cd/:id">
          <CD />
        </Route>
        <Route path="/legislative-leadership">
          <LegislatureLeadership />
        </Route>
        <Route path="/legislative-committees">
          <LegislatureCommittees />
        </Route>
        <Route path="/chair/:id">
          <Chairs />
        </Route>
        <Route path="/committee/:id">
          <Committee />
        </Route>

        <Route path="/executive-offices">
          <ExecutiveOffices />
        </Route>
        <Route path="/independent-quasi-public-agencies">
          <IndependentQuasiPublicAgencies />
        </Route>
        <Route path="/agency-directory">
          <AgencyDirectory />
        </Route>
        <Route path="/governors-council">
          <GovernorsCouncil />
        </Route>
        <Route path="/executive/:id">
          <Executive />
        </Route>
        <Route path="/division/:id">
          <Division />
        </Route>
        <Route path="/agency/:id">
          <Agency />
        </Route>
        <Route path="/executive-office/:parent_id/:id?">
          <ExecutiveOffice />
        </Route>

        <Route path="/supreme-judicial-court">
          <SupremeJudicialCourt />
        </Route>
        <Route path="/appeals-court">
          <AppealsCourt />
        </Route>
        <Route path="/trial-court">
          <TrialCourt />
        </Route>
        <Route path="/judicial/:id">
          <Judicial />
        </Route>

        <Route path="/county-sheriffs/:id">
          <CountySheriffs />
        </Route>
        <Route path="/county-sheriffs">
          <Counties />
        </Route>
        <Route path="/sheriff/:id">
          <CountySheriff />
        </Route>

        <Route path="/personnel-file/:id">
          <PersonnelFile />
        </Route>
      </Switch>
      {!isContainerLoading && <Container maxWidth={false} disableGutters dangerouslySetInnerHTML={{ __html: data.freetext.moves }} />}
    </main>
  </>);
}

export default Navigation;
