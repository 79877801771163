import "./news.css";

import React, { useEffect, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";

function News() {
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/home`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res.ok) {
        throw new Error(await res.text());
      }

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/vacancies`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res1.ok) {
        throw new Error(await res1.text());
      }

      const resJson = await res.json()

      setData({ news: resJson.entries, freetext: resJson.freetext, personnel_files: resJson.personnel_files, vacancies: await res1.json() });
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <div className="responsive-table" dangerouslySetInnerHTML={{__html: data.freetext.secondary_content}} />
  );
}

export default News;
