import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga4";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import { AuthProvider } from "./contexts/auth";

ReactGA.initialize("G-6MC3F6P3NF");

function App() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
  }));

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <AuthProvider>
        <CssBaseline />
        <AppBar handleDrawerToggle={handleDrawerToggle} />
        <div className={classes.root}>
          <Drawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
